.overlay-container {
    position: relative;
    display: inline-block;
}

.overlay-image {
    width: 100%;
    height: auto;
}

.overlay-button-container {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay-container:hover .overlay-button-container {
    opacity: 1;
}