input.flatpickr-mobile {
  display: block;
  width: 100%;
  padding: 0.775rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-gray-700);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid var(--bs-gray-300);
  appearance: none;
  border-radius: 0.475rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.clickable-list-item.active {
  background-color: #f6f6f6;
}

.text-ellipsis {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#ticket-list {
  height: calc(100vh - 250px);
}

.message-list {
  height: calc(100vh - 270px);

  &.disable-chat {
    height: calc(100vh - 205px);
  }
}

.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item.show .nav-link,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid var(--bs-primary);
  transition: color 0.2s ease;
  color: var(--bs-primary-active);
  font-weight: 500;
}

.simple-toggle-checkbox {
  flex-wrap: wrap;
}
.simple-toggle-checkbox .btn {
  margin: 4px 8px 4px 0px;
  border-radius: 8px !important;
  padding: 6px 12px !important;
  font-size: 0.8rem;
  border: 1px solid #f1f1f1 !important;
  flex-grow: 0;
  i {
    font-size: 12px;
  }
}

.simple-toggle-checkbox .btn-check:checked + .btn {
  color: #fff !important;
  i {
    color: #fff;
  }
}

.sticky-top {
  z-index: 9;
}
.sticky-start {
  position: sticky;
  z-index: 10;
  left: 0;
}

.sticky-end {
  position: sticky;
  z-index: 10;
  right: 0;
}

.summary-ticket-card {
  height: 150px;
}

.react-select-white .react-select__control {
  background-color: #fff !important;
}

.select-sm {
  font-size: 0.95rem;
  line-height: 1.1;
}
